// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-calendar-page-js": () => import("./../../../src/templates/calendar-page.js" /* webpackChunkName: "component---src-templates-calendar-page-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-exhibition-post-js": () => import("./../../../src/templates/exhibition-post.js" /* webpackChunkName: "component---src-templates-exhibition-post-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-holding-court-page-js": () => import("./../../../src/templates/holding-court-page.js" /* webpackChunkName: "component---src-templates-holding-court-page-js" */),
  "component---src-templates-holding-court-post-js": () => import("./../../../src/templates/holding-court-post.js" /* webpackChunkName: "component---src-templates-holding-court-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-join-page-js": () => import("./../../../src/templates/join-page.js" /* webpackChunkName: "component---src-templates-join-page-js" */),
  "component---src-templates-journal-page-js": () => import("./../../../src/templates/journal-page.js" /* webpackChunkName: "component---src-templates-journal-page-js" */),
  "component---src-templates-journal-post-js": () => import("./../../../src/templates/journal-post.js" /* webpackChunkName: "component---src-templates-journal-post-js" */),
  "component---src-templates-noah-davis-prize-page-js": () => import("./../../../src/templates/noah-davis-prize-page.js" /* webpackChunkName: "component---src-templates-noah-davis-prize-page-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/page-post.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-past-exhibitions-page-js": () => import("./../../../src/templates/past-exhibitions-page.js" /* webpackChunkName: "component---src-templates-past-exhibitions-page-js" */),
  "component---src-templates-press-release-post-js": () => import("./../../../src/templates/press-release-post.js" /* webpackChunkName: "component---src-templates-press-release-post-js" */),
  "component---src-templates-purple-garden-cinema-page-js": () => import("./../../../src/templates/purple-garden-cinema-page.js" /* webpackChunkName: "component---src-templates-purple-garden-cinema-page-js" */),
  "component---src-templates-support-page-js": () => import("./../../../src/templates/support-page.js" /* webpackChunkName: "component---src-templates-support-page-js" */),
  "component---src-templates-visit-page-js": () => import("./../../../src/templates/visit-page.js" /* webpackChunkName: "component---src-templates-visit-page-js" */),
  "component---src-templates-wellness-page-js": () => import("./../../../src/templates/wellness-page.js" /* webpackChunkName: "component---src-templates-wellness-page-js" */)
}

